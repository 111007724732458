import "./App.css";
import {
  React,
  chunks,
  imageType,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { isIOS } from "react-device-detect";
import { instance } from "./interceptor";
import Webcam from "react-webcam";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { ImLocation2 } from "@react-icons/all-files/im/ImLocation2";
import { Buffer } from "buffer";
import deviceInfo from "./device";
import tolandscape from "./image/tolandscape.gif";
import camera from "./image/camera.png";
import close from "./image/close.png";
import map from "./image/map.png";
import maximize from "./image/maximize.png";
import minimize from "./image/minimize.png";
import location1 from "./image/location1.png";
import location2 from "./image/location2.png";
import { Helmet } from "react-helmet";
const screenfull = require("screenfull");

// import Img from 'react-fix-image-orientation';
// import Listener from  'orientation-listener';

let listurl = [];
let mp = null;
let filename = "";

function Cam() {
  let [zoom, setZoom] = useState(true);
  let [Iphone, setIphone] = useState(false);
  const [FScreen, setFscreen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [picname, setpicname] = useState(null);
  const { state } = useLocation();
  const [url, setUrl] = useState("");
  const [items, setItems] = useState([]);
  const [locat, setloact] = useState(null);
  const [ip, setiP] = useState(null);

  //finding location
  const [lat, setLat] = useState(null);
  const [lid, setlid] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);

  //getting ip address
  function getip() {
    fetch("https://geolocation-db.com/json/", {
      method: "get",
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.IPv4) {
          setiP(data.IPv4);
        } else {
        }
      });
  }

  /* Set up getCurrentPosition options with a timeout */
  const navigatorLocationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  //getting location
  function getLocation() {
    try {
      if (!navigator.geolocation) {
        setStatus("Geolocation is not supported by your browser");
      } else {
        setStatus("Locating...");

        window.navigator.geolocation.getCurrentPosition((position) => {
          if (position != undefined) {
            setStatus(null);
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);

            fetch(
              "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                position.coords.latitude +
                "," +
                position.coords.longitude +
                "&key=AIzaSyCRuGBtjPjc7oVKSEmqaaeax9abDSa4SJ8",
              {
                method: "post",
              }
            )
              .then((resp) => resp.json())
              .then((data) => {
                if (data.results == "") {
                  //getLocationError("unable to access location")
                } else {
                  setloact(data.results[7].formatted_address);
                }
              });
          } else {
            console.log("position not found");
          }
        });
      }
    } catch (e) {
      deviceInfo(lid, e); //Getting rendering device info and error
      console.log("catch1" + deviceInfo(lid, e));
    }
  }

  // If GetLocation caught error
  function getLocationError(err) {
    setStatus("Unable to retrieve your location");
    deviceInfo(base64ToStringNew, err);
  }

  //Decoding Base64Url(refid)
  let base64ToStringNew = "";
  useEffect(() => {
    if (isIOS) {
      Iphone = true;
      setIphone(Iphone);
    }
    if (state != null) {
      if (state.leadid != null) {
        let buff = new Buffer.from(state.leadid, "base64");
        base64ToStringNew = buff.toString("ascii");
        setlid(base64ToStringNew);
      }
    }

    //set image name
    if (state.img == "1") {
      setpicname("ODOMETER");
    }
    if (state.img == "2") {
      setpicname("DRIVER'S SIDE");
    }
    if (state.img == "3") {
      setpicname("FRONT");
    }
    if (state.img == "4") {
      setpicname("FRONT LEFT CORNER");
    }
    if (state.img == "5") {
      setpicname("INTERIOR");
    }
    if (state.img == "6") {
      setpicname("PASSENGER'S SIDE");
    }
    if (state.img == "7") {
      setpicname("REAR LEFT");
    }
    if (state.img == "8") {
      setpicname("REAR RIGHT");
    }
    if (state.img == "9") {
      setpicname("REAR");
    }
    if (state.img == "10") {
      setpicname("TIRE THREAD");
    }
    if (state.img == "11") {
      setpicname("VIN ON SIDE OF THE DOOR");
    }
    if (state.img == "12") {
      setpicname("WINDSHIELD");
    }
  }, []);

  //Webcam Requirements
  const navigate = useNavigate();
  const { innerWidth, innerHeight } = window;

  const [imgSrc, setImgSrc] = useState();
  const webcamRef = useRef();
  const videoConstraints = {
    width: 1280,
    facingMode: "environment",
  };

  //Redirecting to home page
  const redirect = () => {
      navigate("/?refid=" + state.leadid, {
        state: {
          List: listurl,
        },
      });
      
    
  };
  if (window.onbeforeunload != null) {
    redirect();
  } else {
    console.log("onbeforeunload null");
  }
  //save image url to the database
  const saveimgurl = (durl, TypeId, containType, Imgname) => {
    try {
      let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Accept-Encoding": "gzip, deflate, br",
        // connection: "keep-alive",
      };

      let datas = {
        events: "SESSION:STAGE_CHANGE",
        photo_url: durl,
        session_key: base64ToStringNew,
        photo_label: Imgname,
        source: "AUTOBUY",
        message: "PROCESS",
        photo_code: containType,
      };
      console.log(datas, "datassss");


      return instance
        .post("AB_Inspect_Webhook", datas, {
          headers: headers,
        })
        // .then((res) => res.json(
          

        // ))
        .then(
          (result) => {
            console.log(result, "dataurlll");
            navigate("/?refid=" + state.leadid, {
              state: {
                List: listurl,
              },
            });


            setItems(result);
          },
          (error) => {
            console.log(error.message, "error");
            alert(error.message + ": Please try again");
            setTimeout(() => {

            navigate("/?refid=" + state.leadid, {
              state: {
                List: listurl,
              },
            });
          }, 2000);
             
             deviceInfo(base64ToStringNew, error); //Getting rendering device info and error

            // setIsLoaded(true);
            setError(error);
          }
        );
    } catch (e) {   
      deviceInfo(base64ToStringNew, e); //Getting rendering device info and error
      console.log("catch2" + deviceInfo(base64ToStringNew, e));
    }
  };

  var elem = document.documentElement;
  function openFullscreen() {
    setFscreen(true);
    setZoom(false);
    if (elem.requestFullScreen) {
      elem.requestFullScreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen(); //for iphone this code worked
    }
  }
  console.log(elem);

  function closeFullscreen() {
    setFscreen(false);
    setZoom(true);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
  //Webcam Fullscreen Event
  const fullScreenChange = () => {
    console.log("Calling Fullscreen");
    if (screenfull.isFullscreen) {
      setFscreen(false);
    } else {
      setFscreen(true);
    }
  };
  if (screenfull.isEnabled) {
    screenfull.on("change", fullScreenChange);

    fullScreenChange();
  } else {
    // alert("iOS doesn't support fullscreen (yet)");
  }

  //capture and upload image to cloudinary
  const capture = useCallback(() => {
    try {
      setIsLoaded(true);
      mp += 1;

      const imageSrc = webcamRef.current.getScreenshot();

      const byteNumbers = new Array(imageSrc.length);
      for (let i = 0; i < imageSrc.length; i++) {
        byteNumbers[i] = imageSrc.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "image/png",
      });
      let d = new Date();
      const data = new FormData();
      filename =
        base64ToStringNew.replace("\n", "") +
        "-" +
        state.containType +
        "-" +
        d.getMinutes().toString() +
        d.getSeconds().toString(); //image filename Format in cloudinary

      // REACT_APP_API_KEY1CLOUDINARYURL = 'https://api.cloudinary.com/v1_1/rck-techiees-pvt-ltd/image/upload'
      // REACT_APP_API_KEY2COLUDNAME = 'rck-techiees-pvt-ltd'
      // REACT_APP_API_KEY3CLOUDPRESET = 'qyhxvqkz'
      // REACT_APP_API_KEY4APIKEY = '338873942734482'
      // REACT_APP_API_KEY5SECRET= 'RtP4F9vjfn0f3FqEBuO3GeL3nNE'
      data.append("cloud_name", process.env.REACT_APP_API_KEY2COLUDNAME);
      data.append("file", blob, filename);
      data.append("upload_preset", process.env.REACT_APP_API_KEY3CLOUDPRESET);
      data.append("api_key", process.env.REACT_APP_API_KEY4APIKEY);
      data.append("api_secret", process.env.REACT_APP_API_KEY5SECRET);
      data.append(
        "public_id",
        base64ToStringNew.replace("\n", "").toString() + "/" + filename
      );
      data.append("Tags", ["autobuy", "images_lead"]);
      fetch(
        "https://api.cloudinary.com/v1_1/rck-techiees-pvt-ltd/image/upload",
        {
          method: "post",
          body: data,
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          let TypeId = 0;
          let Imgname = "";
          let containType = "";
         
          // navigate("/", {
          //   state: {
          //     img: listurl,
          //   },
          // });
          if (state.img == "1") {
            TypeId = 9;
            Imgname = "ODOMETER";
            containType = "017";
            listurl[0] = {
              img: data.url,
              id: "1",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }

          if (state.img == "2") {
            TypeId = 10;
            Imgname = "DRIVER'S SIDE";
            containType = "029";
            listurl[1] = {
              img: data.url,
              id: "2",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "3") {
            TypeId = 11;
            Imgname = "FRONT";
            containType = "001";
            listurl[2] = {
              img: data.url,
              id: "3",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "4") {
            TypeId = 12;
            Imgname = "FRONT LEFT CORNER";
            containType = "011";
            listurl[3] = {
              img: data.url,
              id: "4",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "5") {
            TypeId = 13;
            Imgname = "INTERIOR";
            containType = "005";
            listurl[4] = {
              img: data.url,
              id: "5",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "6") {
            TypeId = 14;
            Imgname = "PASSENGER'S SIDE";
            containType = "010";
            listurl[5] = {
              img: data.url,
              id: "6",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "7") {
            TypeId = 15;
            Imgname = "REAR LEFT";
            containType = "007";
            listurl[6] = {
              img: data.url,
              id: "7",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "8") {
            TypeId = 16;
            Imgname = "REAR RIGHT";
            containType = "018";
            listurl[7] = {
              img: data.url,
              id: "8",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "9") {
            TypeId = 17;
            Imgname = "REAR";
            containType = "025";
            listurl[8] = {
              img: data.url,
              id: "9",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "10") {
            TypeId = 18;
            Imgname = "TIRE THREAD";
            containType = "002";
            listurl[9] = {
              img: data.url,
              id: "10",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "11") {
            TypeId = 19;
            Imgname = "VIN ON SIDE OF THE DOOR";
            containType = "004";
            listurl[10] = {
              img: data.url,
              id: "11",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          if (state.img == "12") {
            TypeId = 20;
            Imgname = "WINDSHIELD";
            containType = "028";
            listurl[11] = {
              img: data.url,
              id: "12",
            };
            saveimgurl(data.url, TypeId, containType, Imgname);
          }
          
          setUrl(data.url);
        });
      setIsLoaded(false);
    } catch (e) {
      deviceInfo(base64ToStringNew, e); //Getting rendering device info and error
      console.log("catch3" + deviceInfo(base64ToStringNew, e));
    }
  }, [webcamRef, setImgSrc, setIsLoaded]);

  return (
    <>
      <div className="container-fluid pd-0">
        <div className="vid_container">
          <div className="row btn-div-hdr">
            <div className="col-xs-4-1">
              <div className="idtxt">{lid != null ? lid : "id"}</div>
            </div>
            <div className="col-xs-4-2">
              <div className="idtxt2">
                {picname != "" ? picname : "picname"}
              </div>
            </div>
            <div className="col-xs-4-3 ">
              <div className="close-icon">
                {/* <button onClick={redirect} className='cls-btn' >  
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cl-icon-2 bi bi-x-circle-fill" viewBox="0 0 16 16"> 
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/> 
</svg>  
    </button>  */}

                {zoom && (
                  <img
                    src={require("./image/close.png")}
                    onClick={redirect}
                    width="30"
                    height="30"
                    className="cl-icon-2 bi bi-x-circle-fill"
                    alt=""
                  />
                )}
              </div>
            </div>

            <div className="col-xs-12">
              {Iphone ? (
                <div className="iphone-non">
                  <ImLocation2 onClick={getLocation} className="loc-ico" />
                  {locat != null ? locat : ""}
                </div>
              ) : (
                <div className="iconloc">
                  <ImLocation2 onClick={getLocation} className="loc-ico" />
                  {locat != null ? locat : ""}
                </div>
              )}
            </div>
            <div className="col-xs-12 landsp-img">
              <img
                src={require("./image/tolandscape.gif")}
                width="80"
                height="80"
                className="img-land"
                alt=""
              />
            </div>
          </div>

          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            // forceScreenshotSourceSize="true"
          />

          <div className="row btn-div">
            <img
              src={require("./image/camera.png")}
              onClick={() => {
                capture();
                setIsLoaded(true);
              }}
              className="cmr-btn"
              alt=""
            />

            {/* <button  className='cmr-btn'> <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" color='white' fill="currentColor" class="bi bi-camera2" viewBox="0 0 16 16">  
  <path d="M5 8c0-1.657 2.343-3 4-3V4a4 4 0 0 0-4 4z"/> 
  <path d="M12.318 3h2.015C15.253 3 16 3.746 16 4.667v6.666c0 .92-.746 1.667-1.667 1.667h-2.015A5.97 5.97 0 0 1 9 14a5.972 5.972 0 0 1-3.318-1H1.667C.747 13 0 12.254 0 11.333V4.667C0 3.747.746 3 1.667 3H2a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1h.682A5.97 5.97 0 0 1 9 2c1.227 0 2.367.368 3.318 1zM2 4.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0zM14 8A5 5 0 1 0 4 8a5 5 0 0 0 10 0z"/> 
</svg></button>  */}

            {!Iphone && FScreen ? (
              <img
                src={require("./image/minimize.png")}
                id="toggleFullScreenButton"
                name="toggle FullScreen"
                onClick={() => closeFullscreen()}
                aria-pressed="true"
              />
            ) : (
              !Iphone && (
                <img
                  src={require("./image/maximize.png")}
                  id="toggleFullScreenButton"
                  name="toggle FullScreen"
                  onClick={() => openFullscreen()}
                  aria-pressed="false"
                />
              )
            )}
          </div>

          {isLoaded && (
            <div className="loader">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          {imgSrc && <img src={imgSrc} />}
        </div>
      </div>
    </>
  );
}

export default Cam;
