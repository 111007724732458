import "./App.css";
import { React, useEffect, useState } from "react";
import { instance } from "./interceptor";
import { Buffer } from "buffer";
import "bootstrap/dist/css/bootstrap.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIOS,
} from "react-device-detect";
import img1 from "./image/img1.jpeg";
import img2 from "./image/img2.jpeg";
import img4 from "./image/img4.jpeg";
import img5 from "./image/img5.jpeg";
import img6 from "./image/img6.jpeg";
import img7 from "./image/img7.jpeg";
import img8 from "./image/img8.jpeg";
import img9 from "./image/img9.jpeg";
import img10 from "./image/img10.jpeg";
import img11 from "./image/img11.jpeg";
import img12 from "./image/img12.jpeg";
import img13 from "./image/img13.jpeg";
import deviceInfo from "./device";
import "./style/style.css";
import { Img } from "react-image";

function UI() {
  const currentURL = window.location.href; //geting current url
  const [BName, setBName] = useState();
  const [Bvers, setBvers] = useState();
  const [Os, setOs] = useState();
  const [OsV, setOsV] = useState();
  const [Model, setModel] = useState();
  const [bool, setbool] = useState();
  const [myArray, setMyArray] = useState([]);
  const [lid, setlid] = useState(null);
  const params = new URLSearchParams(window.location.search);
  let leadid = params.get("refid");

  const { state } = useLocation();

  //Decoding Base64Url(refId)
  let base64ToStringNew = "";

  let [i, seti] = useState(parseInt(localStorage.getItem('i')) || 0);
  //reload page for initial image loading
  const IninitialLoading=()=>{
    if (localStorage.getItem("i") < 1) {    
      localStorage.setItem("i", i+1);
      setTimeout(() => {
        window.location.reload(); 
      }, 1500);
      seti(i + 1);

    }
  }
  useEffect(() => {

    let buff = new Buffer.from(leadid, "base64");
    base64ToStringNew = buff.toString("ascii");
    loadimg(base64ToStringNew, myArray); //calling loadimg function
    deviceInfo(base64ToStringNew); //Getting rendering device info
    setlid(base64ToStringNew);
  }, [lid, i]);

  const navigate = useNavigate();
  let TypeId = 0;
  let Imgname = "";
  let containType = "";
  const images = [];
  const navigateHome = (data, TypeId, Imgname, containType) => {
    navigate("/Cam", {
      state: {
        img: data,
        leadid: leadid,
        TypeId: TypeId,
        Imgname: Imgname,
        containType: containType,
        browserName: BName,
        browserVersion: Bvers,
        osName: Os,
        osVersion: OsV,
        mobileModel: Model,
      },
    });
  };

  //Loading Existing Image to the container
  const loadimg = async (lead, myArray) => {
    try {
      var datas = '{"leadid":"' + lead + '"}';

      let headers = {
        "Content-Type": "application/json",
        // "Accept-Encoding": "gzip, deflate, br",
        // Connection: "keep-alive",
      };
      if (lead != "[object Object]") {
        //First we are fetching the previously taken images from the database to the image containers after we will capture a new image
        //It will overwrites the existing image by using WebHook appraisal API,that's why we used await to this function.
        const { data } = await instance.post("ABSession_check", datas, {
          headers: headers,
        });
        console.log("data1", data);

        for (let item of JSON.parse(data.d)) {
          console.log("thaha: ", item);
          if (item.imgtype == "017") {
            myArray[0] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img1,
            };
          }

          if (item.imgtype == "029") {
            myArray[1] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img2,
            };
          }
          if (item.imgtype == "001") {
            myArray[2] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img4,
            };
          }
          if (item.imgtype == "011") {
            myArray[3] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img5,
            };
          }
          if (item.imgtype == "005") {
            myArray[4] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img6,
            };
          }
          if (item.imgtype == "010") {
            myArray[5] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img7,
            };
          }
          if (item.imgtype == "007") {
            myArray[6] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img8,
            };
          }
          if (item.imgtype == "018") {
            myArray[7] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img9,
            };
          }
          if (item.imgtype == "025") {
            myArray[8] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img10,
            };
          }
          if (item.imgtype == "002") {
            myArray[9] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img11,
            };
          }
          if (item.imgtype == "004") {
            myArray[10] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img12,
            };
          }
          if (item.imgtype == "028") {
            myArray[11] = {
              test:
                item.imgurl &&
                (item.imgurl.endsWith(".png") ||
                  item.imgurl.endsWith(".jpg") ||
                  item.imgurl.endsWith(".jpeg"))
                  ? item.imgurl
                  : img13,
            };
          }
        }
      }
    } catch (e) {
      deviceInfo(base64ToStringNew, e); //Getting rendering device info and error
      console.log("catch", deviceInfo(base64ToStringNew, e));
    }
  };
  setTimeout(() => {
    setbool(true);
  }, 1000);
  return (
    <>
      {bool && (
        <div className="container-fluid pd-0">
          <h2 className="hdr-1 sticky-top">
            <Img
              src={require("./image/inspect-2.png")}
              width="50"
              height="50"
              className="me-4"
              onLoad={IninitialLoading}
              alt=""
            />
            AutoBuy Inspect
          </h2>

          <div className="container pd-0">
            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-9 inr-cont">
                  <Img
                    id="img_9"
                    className="img-div"
                    onClick={(event) => navigateHome("1", 9, "ODOMETER", "017")}
                    src={
                      myArray.length > 0
                        ? myArray[0]
                          ? myArray[0].test
                          : img1
                        : img1
                    }
                  ></Img>
                  <div
                    id="img_91"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="col-md-4 col-sm-12 element-div">
                <div className="square-10 inr-cont">
                  <Img
                    id="img_10"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("2", 10, "DRIVER'S SIDE", "029")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[1]
                          ? myArray[1].test
                          : img2
                        : img2
                    }
                  />
                  <div
                    id="img_92"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-11 inr-cont">
                  <Img
                    id="img_11"
                    className="img-div"
                    onClick={(event) => navigateHome("3", 11, "FRONT", "001")}
                    src={
                      myArray.length > 0
                        ? myArray[2]
                          ? myArray[2].test
                          : img4
                        : img4
                    }
                  />
                  <div
                    id="img_93"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-12 inr-cont">
                  <Img
                    id="img_12"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("4", 12, "FRONT LEFT CORNER", "011")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[3]
                          ? myArray[3].test
                          : img5
                        : img5
                    }
                  />
                  <div
                    id="img_94"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-13 inr-cont">
                  <Img
                    id="img_13"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("5", 13, "INTERIOR", "005")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[4]
                          ? myArray[4].test
                          : img6
                        : img6
                    }
                  />
                  <div
                    id="img_95"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-14 inr-cont">
                  <Img
                    id="img_14"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("6", 14, "PASSENGER'S SIDE", "010")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[5]
                          ? myArray[5].test
                          : img7
                        : img7
                    }
                  />
                  <div
                    id="img_96"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-15 inr-cont">
                  <Img
                    id="img_15"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("7", 15, "REAR LEFT", "007")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[6]
                          ? myArray[6].test
                          : img8
                        : img8
                    }
                    onLoad={loadimg}
                  />
                  <div
                    id="img_97"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-16 inr-cont">
                  <Img
                    id="img_16"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("8", 16, "REAR RIGHT", "018")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[7]
                          ? myArray[7].test
                          : img9
                        : img9
                    }
                  />
                  <div
                    id="img_98"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-17 inr-cont">
                  <Img
                    id="img_17"
                    className="img-div"
                    onClick={(event) => navigateHome("9", 17, "REAR", "025")}
                    src={
                      myArray.length > 0
                        ? myArray[8]
                          ? myArray[8].test
                          : img10
                        : img10
                    }
                  />
                  <div
                    id="img_99"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-18 inr-cont">
                  <Img
                    id="img_18"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("10", 18, "TIRE THREAD", "002")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[9]
                          ? myArray[9].test
                          : img11
                        : img11
                    }
                  />
                  <div
                    id="img_100"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-19 inr-cont">
                  <Img
                    id="img_19"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("11", 19, "VIN ON SIDE OF THE DOOR", "004")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[10]
                          ? myArray[10].test
                          : img12
                        : img12
                    }
                  />
                  <div
                    id="img_101"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12 element-div">
                <div className="square-20 inr-cont">
                  <Img
                    id="img_20"
                    className="img-div"
                    onClick={(event) =>
                      navigateHome("12", 20, "WINDSHIELD", "028")
                    }
                    src={
                      myArray.length > 0
                        ? myArray[11]
                          ? myArray[11].test
                          : img13
                        : img13
                    }
                  />
                  <div
                    id="img_102"
                    className="flex-column align-items-center justify-content-center imgUpload"
                  >
                    <div className="row">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                    <div className="row">
                      <p>Image Uploading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UI;
