import logo from "./logo.svg";

import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import UI from "./ui";
import Cam from "./webcam";
//require('dotenv').config()
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Cam" element={<Cam />} />
        <Route path="/" element={<UI />} />
        <Route path="*" element={<UI />} />

      </Routes>
    </BrowserRouter>
  );

}
export default App;
